import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import { pauseVideos, sel } from './utils'

gsap.registerPlugin(ScrollTrigger)

export default function Cases(container) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    const navbar$ = sel('.navbar-w')
    const lo35$ = sel('#lo35')
    const case2$ = sel('#case2')
    const case4$ = sel('#case4')
    const case5$ = sel('#case5')
    const heroHsT = new SplitType('.hero__h', { types: 'lines, words, chars', lineClass: 'st-line' })

    console.log('Cases', lo35$)

    const heroTl = gsap
      .timeline()
      .from(
        heroHsT.chars,
        {
          y: '1.1em',
          rotate: 20,
          stagger: {
            amount: 0.75,
          },
          duration: 0.75,
          ease: 'power4.out',
        },
        0
      )
      .fromTo(
        lo35$,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          onStart() {
            console.log('onStart')
          },
          onComplete() {
            console.log('onComplete')
          },
        },
        1.1
      )
    if (!barb.init) {
      heroTl.from(navbar$, { opacity: 0, duration: 1 }, 1)
    }

    ;[case2$, case4$].forEach((el) => {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,
          markers: true,
        },
        y: 100,
        ease: 'none',
      })
    })
    // ScrollTrigger.refresh()
    return heroTl
  })
  return ctx
}
