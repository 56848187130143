import './style.styl'
import { l, onDomReady, pauseVideos, playVideos, sel, stResizeObserver, vh } from './utils'
import { footer, insights, navbarOld, navbar } from './general'
import Home from './home'
import Approach from './approach'
import About from './about'
import Cases from './cases'
import Work from './work'
import WorkItem from './work-item'
import Insights from './insights'
import Insight from './insight'
import Contact from './contact'
// import FsCC from './fscc'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { lenis } from './lenis'
// import PullToRefresh from 'pulltorefreshjs'
import barba from '@barba/core'
import { restartWebflow } from '@finsweet/ts-utils'

let dataPage = sel('.main-wrapper')?.dataset.barbaNamespace || sel('.page-wrapper')?.dataset.page || sel('body')?.dataset.page
const ctaSec$ = sel('.cta-sec')
const footer$ = sel('.footer')

const pageOverlay$ = document.querySelector('.navbar__page-overlay')
pageOverlay$.style.transition = 'background-color 1s ease'
const pageDim$ = document.querySelector('.navbar__page-dim')
const { navbarHeight } = navbar(dataPage)
const isTouch = ScrollTrigger.isTouch
const screenWidth = window.screen.width
const isTouchScreen = screenWidth <= 991
const realScreenWidth = screenWidth * window.devicePixelRatio
const screenHeight = window.screen.height
const realScreenHeight = screenHeight * window.devicePixelRatio
const screenRatio = screenWidth / screenHeight

const pageOverlayHeight = screenHeight - navbarHeight + 1

stResizeObserver()

gsap.registerPlugin(ScrollTrigger)
gsap.defaults({ ease: 'none' })

let pageCtx, insightsCtx, footerCtx
let nextContainer
const views = [
  {
    namespace: 'home',
    afterEnter() {
      pageInit(Home)
    },
  },
  {
    namespace: 'work',
    afterEnter() {
      pageInit(Work, [isTouchScreen])
    },
  },
  {
    namespace: 'approach',
    afterEnter() {
      pageInit(Approach, [isTouch])
    },
  },
  {
    namespace: 'about',
    afterEnter() {
      pageInit(About)
    },
  },
  {
    namespace: 'cases',
    afterEnter() {
      pageInit(Cases)
    },
  },
  {
    namespace: 'insights',
    async afterEnter() {
      await pageInit(Insights)
    },
  },
  {
    namespace: 'insight',
    afterEnter() {
      pageInit(Insight)
    },
  },
  {
    namespace: 'contact',
    async afterEnter() {
      await pageInit(Contact)
    },
  },
  {
    namespace: 'work-item',
    afterEnter() {
      pageInit(WorkItem)
    },
  },
  {
    namespace: 'cookie1',
    afterEnter() {
      pageInit(Cookie1)
    },
  },
]
history.scrollRestoration = 'manual'
barba.init({
  // sync: true,
  // cacheIgnore: true,
  transitions: [
    {
      sync: true,
      async leave(data) {
        const next = data.next.container
        next.classList.add('barba-fixed')
        gsap.set(next, { autoAlpha: 0 })
        await gsap
          .timeline({ defaults: { ease: 'power2.in', duration: 1 } })
          .to(
            pageOverlay$,
            {
              height: !isTouchScreen && pageOverlayHeight,
              onComplete() {
                // gsap.set(next, { position: 'relative' })
                // next.classList.remove('barba-fixed')
                // gsap.to(next, { autoAlpha: 1 })
              },
            },
            0
          )
          .to(pageDim$, { autoAlpha: !isTouchScreen && 0.5 }, 0)
          .to(
            [data.current.container, ctaSec$, footer$],
            {
              opacity: isTouch && 0,
              y: !isTouchScreen && 120,
              filter: !isTouchScreen && 'blur(10px)',
              onComplete() {
                const targetScroll = data.trigger === 'back' && data.current.scrollPos ? data.current.scrollPos : 0

                lenis.scrollTo(targetScroll, {
                  immediate: true,
                })
                gsap.set(pageOverlay$, { autoAlpha: 1, height: 0 })
                gsap.set(pageDim$, { autoAlpha: 0 })
                gsap.set([data.current.container, ctaSec$, footer$], { opacity: 1, y: 0, filter: 'none' })
                // to prevent fsAttributes.init fire too soon
                data.next.container.classList.remove('barba-fixed')
                // revert fires immediately in barba hooks (and hooks.enter stops pageInit)
                pageCtx?.revert()
                // offset wait for the custom pageInit to finish
                setTimeout(() => {
                  document.body.classList.remove('barba-transition')
                }, 1000)
              },
            },
            0
          )
      },
      async enter(data) {
        // await gsap.timeline({ defaults: { ease: 'power2.out', duration: 1 } }).to(
        //   data.next.container,
        //   {
        //     // autoAlpha: 1,
        //     onComplete() {},
        //   },
        //   0
        // )
      },

      once(data) {
        footerCtx = footer()
        nextContainer = data.next.container
      },
    },
  ],
  views: views,
})
let barbaFirstLoad = true
const knownNamespaces = views.map((view) => view.namespace)
barba.hooks.afterEnter(async (data) => {
  // console.log('hook afterEnter', data)
  const ns = data?.next.namespace || ''
  if (!knownNamespaces.includes(ns)) {
    // unknown page, default fade in
    gsap.from(data.next.container, { y: 30, ease: 'power4.out', duration: 1 })
    gsap.to(data.next.container, { autoAlpha: 1 })
    pageInit()
  } else {
    // known page, custom gsap init
    gsap.set(data.next.container, { autoAlpha: 1 })
  }
})
barba.hooks.before((data) => {
  // console.log('hook before')
  data.current.scrollPos = window.pageYOffset || window.scrollY
})
barba.hooks.beforeLeave((data) => {
  // console.log('hook beforeLeave', data)

  dataPage = data.next.namespace
  // data.next.container.classList.add('barba-fixed')
  barbaFirstLoad = false
  if (!isTouch) document.body.classList.add('barba-transition')
})
barba.hooks.beforeEnter((data) => {
  // console.log('hook beforeEnter')
  nextContainer = data.next.container
  insightsCtx?.revert()
})

// barba.hooks.after((data) => {
//   console.log('hook after')
// })

async function pageInit(func, args = []) {
  // console.log(func ? 'func' + ctx : 'x')
  // console.log('pageInit')

  if (pageCtx && fsAttributes) {
    // have to be timer offset (no idea) and in this order (no idea)
    setTimeout(() => {
      // in this particular order because finsweet voodoo
      window.fsAttributes.linkblockedit?.destroy()
      window.fsAttributes.linkblockedit?.init()
      window.fsAttributes.cmsload?.destroy()
      window.fsAttributes.cmsload?.init()
      window.fsAttributes.cmsfilter?.destroy()
      window.fsAttributes.cmsfilter?.init()
    }, 100)
  }
  // in this particular place because of the About tabs w--current issue
  if (pageCtx) window.Webflow.ready()
  if (func) pageCtx = await func(nextContainer, ...args)
  insightsCtx = insights(nextContainer, isTouchScreen, isTouch)
  playVideos()
  ScrollTrigger.refresh()
}
// to prevent refresh on same page, considering barbajs
function hrefHandler(e) {
  const link = e.target.closest('a')

  if (!link || link.href.endsWith('#') || link.classList.contains('.w-tab-link')) return
  if (link.href === window.location.href) {
    e.preventDefault()
  }
}

document.addEventListener('click', hrefHandler)

if (isTouch) {
  // document.body.insertAdjacentText('afterbegin', navigator.userAgent.includes('CriOS'))

  const ipad7GenHeight = [2420, 2752]
  const isIpad7GenRatio = screenRatio === 0.75
  if (isIpad7GenRatio && ipad7GenHeight.includes(realScreenHeight) && navigator.userAgent.includes('CriOS')) {
    // preventing the hell in chrome on ipad pro 7th gen, so the scroll is managed by lenis
    // lenis.options.syncTouch = true
    // lenis.options.easing = (x) => Math.sin((x * Math.PI) / 2)
    // lenisInit()
  } else {
    lenis.destroy()
  }
  // preventing scroll stops/freezes on touch devices
  // ScrollTrigger.normalizeScroll({ allowNestedScroll: true })
  // ScrollTrigger.normalizeScroll(false)

  // ScrollTrigger.config({ ignoreMobileResize: true })
} else {
  lenisInit()
}
// PullToRefresh.init({
//   mainElement: '.main-wrapper', // above which element?
//   onRefresh(done) {
//     window.location.reload()
//     setTimeout(() => {
//       done() // end pull to refresh
//       // alert('refresh')
//     }, 1500)
//   },
// })
//
function lenisInit() {
  lenis.on('scroll', ScrollTrigger.update)
  gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
  })
  gsap.ticker.lagSmoothing(0)
}
