import SplitType from 'split-type'
import { sel, stLineWrap } from './utils'
import gsap from 'gsap'
import { restartWebflow } from '@finsweet/ts-utils'

export default function Insights(container) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    // console.log('Insights')

    const hero$ = sel('.hero')
    const heroS$ = hero$.querySelector('.text-style-tagline')
    const heroH$ = hero$.querySelector('h1')
    const heroInfo$ = hero$.querySelector('.w-dyn-item')
    const heroBtn$ = hero$.querySelector('.button')
    const insightSec$ = sel('.insight-sec')

    const heroHsT = new SplitType(heroH$, { types: 'lines, words', lineClass: 'st-line' })
    const heroSsT = new SplitType(heroS$, { types: 'lines, words' })
    stLineWrap(heroSsT.lines)

    const heroInfoBorderColor = getComputedStyle(heroInfo$).borderBottomColor
    const heroInfoBorderColorString = heroInfoBorderColor.match(/\(([^)]+)\)/)[1]
    const borderColor = 'rgba(' + heroInfoBorderColorString + ', 0)'

    const heroTl = gsap
      .timeline({ defaults: { ease: 'power4.out' } })
      .from(heroHsT.words, { y: '105%', duration: 1, stagger: 0.05 }, 0)
      .from(heroS$, { opacity: 0, duration: 1.5, stagger: 0.1 }, 0.4)
      // .from(heroSsT.lines, { y: '105%', duration: 1.5, stagger: 0.1 }, 0.4)
      .from(heroInfo$, { borderBottomColor: borderColor, duration: 1, ease: 'none' }, 0.5)
      .from(heroBtn$, { opacity: 0, duration: 1.5 }, 0.5)
      .from(insightSec$, { x: 10, opacity: 0, duration: 1.5 }, 0.5)
    // const catNav$ = sel('.insight__nav')
    // const catNavC$ = catNav$.querySelector('.insight__nav__c')
    //
    // const catLenis = new Lenis({
    //   wrapper: catNav$,
    //   container: catNavC$,
    //   direction: 'horizontal',
    //   smoothWheel: true, // whether to allow wheel/touch in horizontal direction
    //   smoothTouch: true, // whether to allow touch gestures in horizontal direction
    //   gestureDirection: 'both', // can be 'both' | 'vertical' | 'horizontal'
    // })
    // gsap.ticker.add((time) => {
    //   catLenis.raf(time * 1000)
    // })
    // gsap.ticker.lagSmoothing(0)
  })
  // return new Promise((res) => {
  //   setTimeout(() => {
  //     // console.log('Insights', container)
  //     res(ctx)
  //   }, 5000)
  // })
  return ctx
}
