import Splide from '@splidejs/splide'
import '@splidejs/splide/css'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CustomEase } from 'gsap/CustomEase'
import SplitType from 'split-type'
import { addStaticSplideClasses, callAfterResize, mm, onDomReady, pauseVideos, sel, selAll, stLineWrap, stResizeObserver } from './utils'
import { color, primeColor } from './general'
import { lenis } from './lenis'
import { roundCorners } from 'svg-round-corners'
gsap.registerPlugin(ScrollTrigger, CustomEase)

export default function Approach(container, isTouch) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    const hero$ = sel('.hero')
    const heroH$ = sel('.hero__h')
    const heroP$ = hero$.querySelector('.subtitle')
    const heroInfo$ = sel('.about-header__approach')
    const heroInfoH$ = heroInfo$.querySelector('.text-style-tagline')
    const heroInfoP$ = sel('.about-header__approach__p-lo')
    const heroInfoP$a = heroInfoP$.querySelectorAll(':scope>div')

    const heroHsT = new SplitType(heroH$, { types: 'lines, words', lineClass: 'st-line' })
    const heroPsT = new SplitType(heroP$, { types: 'lines, words' })
    stLineWrap(heroPsT.lines)

    const heroTl = gsap
      .timeline({ defaults: { ease: 'power4.out' } })
      .from(heroHsT.words, { y: '100%', duration: 1.5, stagger: 0.15 }, 0)
      .from(heroPsT.lines, { y: '100%', duration: 1.5, stagger: 0.1 }, 0.4)
      .from([heroInfoH$, heroInfoP$a], { x: 10, opacity: 0, duration: 1.5, stagger: 0.1 }, 0.5)

    const step$a = selAll('.steps__item')
    const stepList$ = sel('.steps__list')
    const stepSvg$a = selAll('.steps__item_svg')

    function drawStepsLine() {
      const lineFg = 'steps__line-fg'
      const lineBg = 'steps__line-bg'

      // Clear old SVG
      const oldFgSvg = sel('.' + lineFg)
      if (oldFgSvg) oldFgSvg.remove()
      const oldBgSvg = sel('.' + lineBg)
      if (oldBgSvg) oldBgSvg.remove()

      // Create an SVG
      const svgNS = 'http://www.w3.org/2000/svg'
      const bgSvg = document.createElementNS(svgNS, 'svg')
      bgSvg.setAttribute('class', lineBg)
      bgSvg.style.position = 'absolute'
      bgSvg.style.pointerEvents = 'none'
      stepList$.appendChild(bgSvg)

      // Build path through centers
      const stepListRect = stepList$.getBoundingClientRect()

      const centers = Array.from(stepSvg$a).map((el) => {
        const r = el.getBoundingClientRect()
        return {
          x: r.left + r.width / 2 - stepListRect.left,
          y: r.top + r.height / 2 - stepListRect.top,
        }
      })
      const padding = 15
      let pathData = `M ${centers[0].x},${centers[0].y + padding}`
      // 1->2 : bottom then right
      pathData += ` L ${centers[0].x},${centers[1].y} L ${centers[1].x - padding},${centers[1].y}`
      // 2->3 : bottom then right
      pathData += ` L ${centers[1].x - padding},${centers[2].y} L ${centers[2].x - padding},${centers[2].y}`
      // 3->4 : direct
      pathData += ` L ${centers[3].x - padding},${centers[3].y}`
      // 4->5 : left then bottom
      pathData += ` L ${centers[4].x},${centers[3].y} L ${centers[4].x},${centers[4].y - padding}`
      // 5->6 : left then bottom
      pathData += ` L ${centers[5].x},${centers[4].y - padding} L ${centers[5].x},${centers[5].y}`

      const roundPath = roundCorners(pathData, 20, 2).path
      // console.log(roundPath)

      // Draw the path
      const path = document.createElementNS(svgNS, 'path')
      path.setAttribute('d', roundPath)
      path.setAttribute('fill', 'none')
      path.setAttribute('stroke', '#E0E2E1')
      path.setAttribute('stroke-width', '1')
      bgSvg.appendChild(path)

      // Adjust SVG size to fit the path
      const bbox = bgSvg.getBBox()
      bgSvg.setAttribute('width', bbox.width + 23) // extra 3px on 4k to address the clipping
      bgSvg.setAttribute('height', bbox.height + 20)

      const fgSvg = bgSvg.cloneNode(true)
      fgSvg.setAttribute('class', lineFg)
      fgSvg.querySelector('path').style.stroke = color.floor

      stepList$.appendChild(fgSvg)
      const fgPath = fgSvg.querySelector('path')

      const length = fgPath.getTotalLength()
      fgPath.style.strokeDasharray = length
      fgPath.style.strokeDashoffset = length

      const stepTl = gsap.timeline({}).fromTo(
        fgPath,
        { strokeDashoffset: length },
        {
          strokeDashoffset: 0,
          ease: 'none',
          // scrollTrigger: {
          //   trigger: stepList$,
          //   start: 'top 50%',
          //   end: 'bottom 90%',
          //   scrub: true,
          // },
        }
      )

      step$a.forEach((step$, i) => {
        // const time = i > 2 ? i * 2 - 1 : i * 2
        // const duration = i === 2 ? 1 : 2
        // console.log(i, time, duration)
        // console.log(step$)

        const h$ = step$.querySelector('.heading-style-h3:not(.is--step-num)')
        const hSt = new SplitType(h$, { types: 'lines, words', lineClass: 'st-line' })
        stLineWrap(hSt.lines)
        const info$a = step$.querySelectorAll('.steps__item__info>div')

        const stepInfoTl = gsap
          .timeline({ defaults: { ease: 'power4.out' } })
          .from(hSt.lines, { opacity: 0, xPercent: -100, duration: 1.5 }, 0)
          .from(info$a, { opacity: 0, x: -20, stagger: 0.1, duration: 2 }, 0.3)
        ScrollTrigger.create({
          trigger: step$,
          start: 'top 80%',
          end: '+=1',
          toggleActions: 'play none reverse none',
          animation: stepInfoTl,
        })
      })
      ScrollTrigger.create({
        trigger: stepList$,
        start: 'top 50%',
        end: 'bottom 90%',
        animation: stepTl,
        scrub: 1,
      })
    }

    drawStepsLine()
    if (!isTouch) callAfterResize(drawStepsLine)
  })
  return ctx
}
