import SplitType from 'split-type'
import { easeRev, l, sel, selAll, stLineWrap } from './utils'
import gsap from 'gsap'
import { color } from './general'

import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
export default function Work(container, isTouchScreen) {
  const ctx = gsap.context(() => {
    const sel = container.querySelector.bind(container)
    const selAll = container.querySelectorAll.bind(container)
    const hero$ = sel('.hero')
    const heroH$ = sel('.hero__h')
    const heroP$ = hero$.querySelector('.subtitle')
    const heroInfo$ = sel('.is--work-header')
    const casesSec$ = sel('.cases-sec')

    const heroHsT = new SplitType(heroH$, { types: 'lines, words', lineClass: 'st-line' })
    const heroPsT = new SplitType(heroP$, { types: 'lines, words' })
    stLineWrap(heroPsT.lines)

    const heroInfoBorderColor = getComputedStyle(heroInfo$).borderBottomColor
    const heroInfoBorderColorString = heroInfoBorderColor.match(/\(([^)]+)\)/)[1]
    const borderColorTransparent = 'rgba(' + heroInfoBorderColorString + ', 0)'
    const borderColorOriginal = 'rgba(' + heroInfoBorderColorString + ', 1)'
    // console.log(borderColor, heroInfoBorderColor)

    const heroTl = gsap
      .timeline({ defaults: { ease: 'power4.out' } })
      .from(heroHsT.words, { y: '100%', duration: 1.5, stagger: 0.15 }, 0)
      .from(heroPsT.lines, { y: '100%', duration: 1.5, stagger: 0.1 }, 0.4)
      // .from(heroInfo$, { borderBottomColor: 'color-mix(in srgb, var(--color--gray-2), transparent 100%)', duration: 3, ease: 'none' }, 0.5)
      .from(heroInfo$, { borderBottomColor: borderColorTransparent, duration: 1, ease: 'none' }, 0.5)
      .from(casesSec$, { x: 10, opacity: 0, duration: 1.5 }, 0.5)

    let hovers = [1, 0]
    let prevItemNum = 0
    let prevItem = {}
    const maskFull = 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)'
    const casesItem$a = selAll('.cases__item-sm')
    let x = 0

    function itemAnimate(el, tlDirection = 'in', triggerArea = 'top') {
      // console.log('start ani')
      const enter = tlDirection === 'in' ? true : false
      const item = el
      const h$ = item.querySelector('a')
      const hW$ = item.querySelector('.cases__h-w')
      const s$ = item.querySelector('.text-style-tagline')
      const p$ = item.querySelector('h3')
      const pW$ = item.querySelector('.cases__p-w')
      const imgW$ = item.querySelector('.cases__item__bg-w')
      const imgWW$ = item.querySelector('.cases__item__bg-ww')
      const img$ = imgW$.querySelector('img')
      const w$ = item.querySelector('.is--case')

      let maskHoveIn, maskHoverOut

      const skewShift = 30
      if (triggerArea === 'top') {
        maskHoveIn = 'polygon(0px 0px, 100% ' + -skewShift + '%, 100% ' + -skewShift + '%, 0px 0px)' // from top
        maskHoverOut = 'polygon(0px -1px, 100% -1px, 100% -1px, 0px ' + -skewShift + '%)' // to top
      } else {
        maskHoveIn = 'polygon(0px 101%, 100% ' + (100 + skewShift) + '%, 100% 101%, 0px 101%)' // from bottom
        maskHoverOut = 'polygon(0px ' + (100 + skewShift) + '%, 100% 101%, 100% 101%, 0px 101%)' // to bottom
      }
      // console.log('ani')
      const tll = gsap
        .timeline({ defaults: { ease: 'power4.out', overwrite: 'auto' } })
        .set(imgW$, { opacity: 1 }, 0)
        .to([h$, s$, p$], { color: enter ? color.ceiling : color.floor, duration: 1 }, 0)
        .to(pW$, { x: enter && !isTouchScreen ? 20 : 0, duration: 3 }, 0)
        .to(img$, { scale: enter ? 1.1 : 1, duration: 5 }, 0)
      const maskDur = 1
      const maskEase = 'power4.inOut'
      if (enter) {
        // console.log('enter')
        tll.fromTo(imgWW$, { clipPath: maskHoveIn }, { clipPath: maskFull, duration: maskDur, ease: maskEase }, 0)
      } else {
        // console.log('exit')
        tll.to(imgWW$, { clipPath: maskHoverOut, duration: maskDur, ease: maskEase }, 0)
      }
    }
    let lastItemAnimated = 0
    casesItem$a.forEach((item, i) => {
      const currentItemNum = i + 1
      const itemHeight = item.offsetHeight
      //todo mobile click
      item.onclick = () => {
        item.querySelector('a').click()
      }

      function getDirection(e, item) {
        console.log('getDirection')

        const rect = item.getBoundingClientRect()
        // console.log(e.clientY < rect.top + itemHeight / 2)

        const offset = 100 // events fire too soon, so we need to add some offset

        // if (e.clientY - offset <= rect.top) {
        if (e.clientY < rect.top + itemHeight / 2) {
          return 'top'
        } else if (e.clientY + offset >= rect.bottom) {
          return 'bottom'
        } else {
          return 'sides'
        }
      }
      if (ScrollTrigger.isTouch) {
        const itemHeight = item.offsetHeight
        ScrollTrigger.create({
          trigger: item,
          start: 'top 50%',
          // end: 'bottom 40%',
          end: '+=' + itemHeight,
          onEnter: () => {
            itemAnimate(item, 'in', 'top')
          },
          onLeave: () => {
            itemAnimate(item, 'out', 'bottom')
          },
          onEnterBack: () => {
            itemAnimate(item, 'in', 'bottom')
          },
          onLeaveBack: () => {
            itemAnimate(item, 'out', 'top')
          },
        })
      } else {
        item.addEventListener('mouseenter', function (e) {
          const triggerArea = getDirection(e, this)
          itemAnimate(this, 'in', triggerArea)
          lastItemAnimated = currentItemNum
          // console.log('in', triggerArea)
        })

        item.addEventListener('mouseleave', function (e) {
          console.log('mouse out')
          const triggerArea = getDirection(e, this)
          itemAnimate(this, 'out', triggerArea)
          // console.log('out', triggerArea)
        })
      }
    })
  })
  return ctx
}
