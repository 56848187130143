import Splide from '@splidejs/splide'
import '@splidejs/splide/css'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CustomEase } from 'gsap/CustomEase'
import SplitType from 'split-type'
import { addStaticSplideClasses, mm, pauseVideos, sel, selAll, stLineWrap, stResizeObserver, trackDirection } from './utils'
import { color, navbar } from './general'
import { lenis } from './lenis'

gsap.registerPlugin(ScrollTrigger, CustomEase)

export default function Home(container) {
  const ctx = gsap.context(() => {
    const sel = container.querySelector.bind(container)
    const selAll = container.querySelectorAll.bind(container)
    const navbarW$ = document.querySelector('.navbar-w')
    const navbar$ = document.querySelector('.navbar') || navbarW$.querySelector('.w-nav')
    const navbarMenu$ = navbar$.querySelector('.is--menu')
    const navbarBtn$ = navbar$.querySelector('.button.is-secondary')
    const navbarMobBtn$a = navbar$.querySelectorAll('.w-nav-button>div>div')
    const navbarMobMenu$ = sel('.navbar__menu') || navbar$.querySelector('.w-nav-menu')

    const navbarHeight = navbar$.offsetHeight

    const heroH$ = sel('.hero__h')
    const heroP$ = sel('#heroP')
    const heroBtn = sel('#heroBtn')

    const lo35$ = sel('#lo35')
    const casesItem$a = selAll('.cases__item')
    const case2$ = sel('#case2')
    const case4$ = sel('#case4')
    const case5$ = sel('#case5')
    const heroHsT = new SplitType(heroH$, { types: 'lines, words, chars', lineClass: 'st-line' })
    const heroPsT = new SplitType(heroP$, { types: 'lines, words', lineClass: 'st-line' })
    stLineWrap(heroPsT.lines)

    const heroTl = gsap
      .timeline({ defaults: { duration: 1, ease: 'power4.out' } })
      .from(
        heroHsT.words,
        {
          y: '1.1em',
          // rotate: 20,
          stagger: {
            // amount: 0.45,
            each: 0.1,
          },
          duration: 1,
        },
        0
      )
      .from(heroPsT.lines, { opacity: 0, y: '1.1em', stagger: { amount: 0.1 }, duration: 0.8 }, 0.6)
      .from(heroBtn, { opacity: 0, duration: 0.5, ease: 'none' }, 0.8)
      .from(
        [casesItem$a[0], casesItem$a[1]],
        { opacity: 0, y: 50, stagger: 0.1 },
        // {
        //   opacity: 1,
        //   y: 0,
        //   duration: 1,
        // },
        0.55
      )

    casesItem$a.forEach((el) => {
      const img$ = el.querySelector('.cases__item__img')
      const vid$ = el.querySelector('video')
      gsap.from(img$, {
        scale: 1.15,
        ease: 'none',
        scrollTrigger: {
          trigger: el,
          start: 'top 90%',
          end: 'bottom top',
          scrub: 1,
        },
      })

      // const vidTl = gsap.timeline({ paused: true }).to(vid$, { scale: 1.1, ease: 'none' })

      el.addEventListener('mouseenter', () => {
        // gsap.to(vidTl, { duration: 2, progress: 1, ease: 'power4.out' })
      })
      el.addEventListener('mouseleave', () => {
        // gsap.to(vidTl, { duration: 2, progress: 0, ease: 'power4.out' })
      })
    })

    mm.add('(min-width: 768px)', () => {
      // ;[case2$, case4$].forEach((el) => {
      ;[case2$].forEach((el) => {
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1,
            // markers: true,
          },
          y: 100,
          ease: 'none',
        })
      })

      const caseBig$a = selAll('.cases__item__img-w.is--big')
      caseBig$a.forEach((el) => {
        const vid$ = el.firstElementChild
        // const tl = gsap.timeline({}).from(el, { scale: 0.9 })
        // .from(vid$, { scale: 1.1 }, 0)
        ScrollTrigger.create({
          trigger: el,
          start: 'top bottom',
          end: 'bottom bottom',
          scrub: 1,
          // animation: tl,
        })
      })
    })
    // console.log(ScrollTrigger.getAll())
    //
    const casesSec$ = sel('.cases-sec')
    const approachW$ = sel('.approach-w')
    const approachSec$ = sel('.approach-sec')
    const approachInfo$ = sel('.approach__info')
    const approachTag$ = approachInfo$.querySelector('.text-style-tagline')
    const approachBtnW$ = approachInfo$.querySelector('.button-group')

    casesSec$.style.zIndex = 10
    approachW$.style.zIndex = 0
    // gsap.set(approachSec$, { y: '-100%', marginBottom: '-100vh' })

    const approachH$ = sel('.approach__h')
    gsap.set(approachH$, { color: '#ddd' })
    const approachHST = new SplitType(approachH$, { types: 'lines, words, chars', lineClass: 'st-line' })

    const insightW$ = sel('.insight-w')
    const insightSec$ = sel('.insight-sec')

    const approachTl = gsap
      .timeline({ defaults: { ease: 'power1.in', duration: 1 } })
      // .from(approachInfo$, { opacity: 0, duration: 0.1 })
      .to(approachHST.words, { color: color.floor, stagger: { amount: 3, from: 'start' } }, 0)
    /*
  // to smooth the pinning
  const approachTlDuration = approachTl.duration()
  const approachSmooth = 100
  approachTl
    .from(approachInfo$, { y: approachSmooth, ease: 'power1.out', duration: approachTlDuration / 2 }, 0)
    .to(approachInfo$, { y: -approachSmooth, ease: 'power1.in', duration: approachTlDuration / 2 }, approachTlDuration / 2)
  // .from([approachTag$, approachBtnW$], { opacity: 0 }, 0.2)
*/
    ScrollTrigger.create({
      trigger: approachW$,
      start: 'top 5%',
      // endTrigger: insightSec$,
      // end: 'top top',
      // end: '+=150%',
      end: '+=100%',
      // pinSpacing: false,
      pin: approachSec$,
      animation: approachTl,
      scrub: 0,
    })

    const aboutW$ = sel('.about-w')
    const aboutSec$ = sel('.about-sec')
    const aboutGraph$ = sel('.about__graph')
    const circle1$ = sel('.about__graph__circle:not(.is--right)')
    const circle2$ = sel('.about__graph__circle.is--right')
    const circleCenter$ = sel('.about__graph__center')

    const approachBtn1$ = approachInfo$.querySelector('.button:not(.is-secondary)')
    const approachBtn2$ = approachInfo$.querySelector('.button.is-secondary')

    // gsap.set(insightW$, { backgroundColor: color.ceiling, zIndex: 20 })
    gsap.set(aboutSec$, { backgroundColor: color.ceiling, zIndex: 0 })

    const clearProps = {
      onStart: function () {
        gsap.set(this._targets[0], { clearProps: true })
      },
      onReverseComplete: function () {
        gsap.set(this._targets[0], { clearProps: true })
      },
    }

    gsap.set(navbarBtn$, { clearProps: true })

    const aboutStTl = gsap
      .timeline({ defaults: { duration: 1, ease: 'power4.out', immediateRender: false }, paused: true })
      .to([approachSec$, aboutSec$], { backgroundColor: color.floor, color: color.ceiling, ease: 'power4.out' }, 0)
      // .to(approachBtn1$, { backgroundColor: color.ceiling, borderColor: color.ceiling, color: color.floor, ...clearProps }, 0)
      // .to(
      //   approachBtn2$,
      //   {
      //     borderColor: color.ceiling,
      //     color: color.ceiling,
      //     ...clearProps,
      //   },
      //   0
      // )
      .to(approachBtnW$, { '--color--theme-fg': color.ceiling, '--color--theme-bg': color.floor, stagger: 0 }, 0)
    // .to(approachBtn1$, { '--color--theme-fg': color.ceiling, '--color--theme-bg': color.floor }, 0)
    // .to(
    //   approachBtn2$,
    //   {
    //     '--color--theme-fg': color.ceiling,
    //     '--color--theme-bg': color.floor,
    //   },
    //   0
    // )
    // .to(approachHST.words, { color: color.ceiling }, 0.1)

    const navAboutTl = navbarTheme('dark', false, 'power4.out')

    ;[aboutStTl, navAboutTl].forEach((tl) => {
      ScrollTrigger.create({
        trigger: approachSec$,
        start: 'bottom 75%',
        end: '+=100%',
        // pinSpacing: false,
        // pin: aboutSec$,
        animation: tl,
        onLeaveBack: () => {
          gsap.getById('navThemeTl')?.pause()
          gsap.getById('navThemeTlOut')?.pause()
          gsap.getById('navThemeTlIn')?.pause()
        },

        scrub: 0,
      })
    })

    const circleShift = 150
    const circleScale = 1

    const aboutTl = gsap.timeline({ defaults: { ease: 'power1.out', duration: 0.7 } })
    aboutTl
      .from(circle1$, { x: -circleShift, scale: circleScale, opacity: 0 }, 0)
      .from(circle2$, { x: circleShift, scale: circleScale, opacity: 0 }, 0)
      .from([circle1$, circle2$, circleCenter$], { y: 80, ease: 'power1.in' }, 0)
      .from(circleCenter$, { scale: 0.75, opacity: 0 }, 0.75)
      .from(aboutGraph$, { scale: 0.7, duration: 2 }, '>-1.5')
      .to({}, { duration: 1 })

    ScrollTrigger.create({
      trigger: aboutW$,
      start: 'top 50%',
      end: '+=1',

      toggleActions: 'play none reverse reset',
      animation: aboutTl,
    })

    function navbarTheme(theme = 'light', el, ease = 'none') {
      // const navbar$ = sel('.w-nav')
      const navbarHeight = navbar$.offsetHeight
      const light = color.ceiling
      const dark = color.floor

      let fgFrom = light
      let bgFrom = dark
      let fgTo = dark
      let bgTo = light
      let themeFrom = 'dark'
      if (theme === 'dark') {
        fgFrom = dark
        bgFrom = light
        fgTo = light
        bgTo = dark
        themeFrom = 'light'
      }

      const navbarTl = gsap.fromTo(
        navbar$,
        { '--color--theme-fg': fgFrom, '--color--theme-bg': bgFrom },
        {
          '--color--theme-fg': fgTo,
          '--color--theme-bg': bgTo,
          ease: ease,
          paused: true,
          immediateRender: false,
          overwrite: 'auto',
          duration: 0.2,
          id: 'navThemeTl',
          onReverseComplete: () => {
            // navbar$.setAttribute('data-theme', themeFrom)
          },
          onComplete: () => {
            // navbar$.setAttribute('data-theme', theme)
          },
          // to swap the theme in the middle of the animation
          onUpdate: trackDirection({
            onUpdate: function (direction) {
              const currentTheme = navbar$.dataset.theme
              const progress = this.progress()

              if (direction === 1) {
                if (progress >= 0.5 && currentTheme !== theme) {
                  navbar$.setAttribute('data-theme', theme)
                }
              } else {
                if (progress <= 0.5 && currentTheme !== themeFrom) {
                  navbar$.setAttribute('data-theme', themeFrom)
                }
              }
            },
          }),
        }
      )
      if (el) {
        ScrollTrigger.create({
          trigger: el,
          start: 'top top+=' + navbarHeight,
          end: '+=1',
          onEnter({ progress, direction, getVelocity }) {
            // console.log('enter', fg, bg, newFg, newBg, light, dark)
            gsap.to(navbarTl, { duration: 0.5, progress: 1, ease: 'power1.out', id: 'navThemeTlIn' })

            // navbarTl.play()
          },
          onLeaveBack({ progress, direction, getVelocity }) {
            // console.log('back', fg, bg, newFg, newBg, light, dark)
            gsap.to(navbarTl, { duration: 0.5, progress: 0, ease: 'power1.out', id: 'navThemeTlOut' })
            // navbarTl.reverse()
          },
        })
      }
      return navbarTl
    }

    navbarTheme('light', insightW$)
  })
  return ctx
}
