import Splide from '@splidejs/splide'
import '@splidejs/splide/css'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CustomEase } from 'gsap/CustomEase'
import SplitType from 'split-type'
import { addSplideClasses, addStaticSplideClasses, connectSplideArrows, mm, onDomReady, pauseVideos, sel, selAll, stLineWrap, stResizeObserver } from './utils'
import { lenis } from './lenis'
import { MyTransition } from './m1'
import fade from './m2'

gsap.registerPlugin(ScrollTrigger, CustomEase)

export default function About(container) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    const heroH$ = sel('.hero__h')
    const heroS$ = sel('.case-header__info .text-style-tagline')
    const heroP$ = sel('.case-header__info .subtitle')
    const valuesSec$ = sel('.values-sec')

    const heroHsT = new SplitType(heroH$, { types: 'lines, words, chars', lineClass: 'st-line' })
    const heroPsT = new SplitType(heroP$, { types: 'lines, words', lineClass: 'st-line' })
    stLineWrap(heroPsT.lines)

    const heroTl = gsap
      .timeline({ defaults: { duration: 1, ease: 'power4.out' } })
      .from(
        heroHsT.words,
        {
          y: '1.1em',
          stagger: {
            // amount: 0.45,
            each: 0.1,
          },
          duration: 1.5,
        },
        0
      )
      .from(heroPsT.lines, { opacity: 0, y: '1.1em', stagger: { amount: 0.1 }, duration: 0.8 }, 0.6)
      .from(heroS$, { opacity: 0 }, 0.7)
      .from(valuesSec$, { opacity: 0, y: 50 }, 0.7)

    const testSlider$a = selAll('.capabilities__test-slider-w')
    // wf tabs were making SplitType unable to break the lines, had to use observer to wait for visibility to change
    testSlider$a.forEach((item, i) => {
      // to prevent scroll freeze on touch devices without scrollNormalizer
      if (i === 0) {
        // console.log('item', i, item)
        sliderInit()
      } else {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                sliderInit()
                observer.unobserve(entry.target)
              }
            })
          },
          { threshold: 0.1 }
        )
        observer.observe(item)
      }

      function sliderInit() {
        const slider$ = item.querySelector('.capabilities__test-slider')
        const capabilitiesItem$a = item.querySelectorAll('.capabilities__test-slider__c')
        // console.log(capabilitiesItem$a, i)
        capabilitiesItem$a.forEach((item) => {
          const el$a = item.querySelectorAll(':scope>div')
          const client = el$a[0]
          const quote = el$a[1]
          const person = el$a[2]
          // const clientSth = new SplitType(client, { types: 'lines, words' })
          // const personSts = new SplitType(person, { types: 'lines, words' })
          const quoteSth = new SplitType(quote, { types: 'lines' })
          stLineWrap(quoteSth.lines)
        })
        if (addSplideClasses(slider$) !== 'init') return
        const splide = new Splide(slider$, {
          perPage: 1,
          pagination: false,
          arrows: false,
          gap: '3rem',
          autoplay: true,
          rewind: true,
          type: 'fade',
          // loop: true,
          // speed: 600,
          speed: 0,
          breakpoints: {
            768: {
              gap: '2rem',
            },
          },
          // }).mount({ Transition: fade })
        }).mount({}, MyTransition)

        const arrowW$ = item.querySelector('.capabilities__test-slider__nav')
        connectSplideArrows(splide, arrowW$, '.arrow')

        const current$ = item.querySelector('.capabilities__test-slider__current')
        const total$ = item.querySelector('.capabilities__test-slider__total')
        if (!current$ || !total$) console.log(slider$)
        total$.textContent = splide.length
        splide.on('move', (newIndex, oldIndex) => {
          current$.textContent = splide.index + 1
        })
      }
    })
    function clientsInit() {
      addSplideClasses('.clients__slider')
      const splide = new Splide('.clients__slider', {
        perPage: 3,
        pagination: false,
        arrows: false,
        type: 'loop',
        gap: '3rem',
        speed: 1500,
        autoScroll: { speed: 1, pauseOnHover: true },
        easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
        drag: 'free',
        breakpoints: {
          768: {
            perPage: 1,
            gap: 0,
          },
        },
      }).mount({ AutoScroll })
    }
    clientsInit()
  })
  return ctx
}
