import SplitType from 'split-type'
import { easeRev, sel, selAll, stLineWrap } from './utils'
import gsap from 'gsap'
import { color } from './general'

export default function WorkItem(container) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    const hero$ = sel('.case-header__info')
    const heroS$ = hero$.querySelector('.text-style-tagline')
    const heroH$ = sel('.hero__h')
    const heroP$ = hero$.querySelector('.subtitle')
    // const heroInfo$ = sel('.is--work-header')
    const firstImgSec$ = selAll('.work-grid')[0]
    const firstImg$ = sel('.cases__item__img-w:has(.cases__item__img)')
    // console.log(firstImg$)
    const pageSlot$ = hero$.nextElementSibling

    const heroHsT = new SplitType(heroH$, { types: 'lines, words', lineClass: 'st-line' })
    const heroPsT = new SplitType(heroP$, { types: 'lines, words' })
    const heroSsT = new SplitType(heroS$, { types: 'lines, words' })
    stLineWrap(heroPsT.lines)
    stLineWrap(heroSsT.lines)

    const heroTl = gsap
      .timeline({ defaults: { ease: 'power4.out' } })
      .from(heroHsT.words, { y: '100%', duration: 1.5, stagger: 0.15 }, 0)
      .from(heroS$, { opacity: 0, duration: 1.5, stagger: 0.1 }, 0.4)
      // .from(heroSsT.lines, { y: '100%', duration: 1.5, stagger: 0.1 }, 0.4)
      .from(heroPsT.lines, { y: '100%', duration: 1.5, stagger: 0.1 }, 0.4)
      .from(pageSlot$, { y: 50, opacity: 0, duration: 1.5 }, 0.7)

    function rtIsEmpty(container) {
      // Get only element children (ignoring text nodes and pseudo elements)
      const children = Array.from(container.children)

      // Check if there's exactly one child
      if (children.length !== 1) return false

      const child = children[0]

      // Check if the only child is a <p> element
      if (child.tagName.toLowerCase() !== 'p') return false

      // Get the text content and trim any whitespace
      const text = child.textContent.trim()

      // Check if text is empty or equals a zero width joiner.
      // Note: &zwj; in HTML becomes "\u200d" in JavaScript strings.
      return text === '' || text === '\u200d'
    }

    const rtRightCol$a = selAll('.work__col.is--right')
    rtRightCol$a.forEach((el) => {
      const rt$ = el.querySelector('.w-richtext')
      if (rtIsEmpty(rt$)) el.remove()
    })
  })
  return ctx
}
