import SplitType from 'split-type'
import { sel, selAll, stLineWrap } from './utils'
import gsap from 'gsap'

export default function Insight(container) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    const header$a = selAll('.insight-rt__header>*')
    const rtW$ = sel('.insight-rt__rt-w')

    const heroTl = gsap
      .timeline({ defaults: { ease: 'power4.out' } })
      .from(header$a, { x: 20, opacity: 0, duration: 1.5, stagger: 0.25 }, 0)
      .from(rtW$, { y: 10, opacity: 0, duration: 1.5 }, 0.4)
  })
  return ctx
}
