// import './contact.css'
import './contact.styl'
import SplitType from 'split-type'
import { sel, selAll, stLineWrap } from './utils'
import gsap from 'gsap'

export default function Contact(container) {
  const ctx = gsap.context(() => {
    const sel = (el) => container.querySelector(el)
    const selAll = (el) => container.querySelectorAll(el)
    const hero$ = sel('.hero')

    const heroH$ = hero$.querySelector('h1')
    const formInputW$ = selAll('.form_field-wrapper')
    const formBtnW$ = sel('.form__btn-w')
    const heroHsT = new SplitType(heroH$, { types: 'lines, words', lineClass: 'st-line' })

    const heroTl = gsap
      .timeline({ defaults: { ease: 'power4.out' } })
      .from(heroHsT.words, { y: '100%', duration: 1, stagger: 0.1 }, 0)
      .from([formInputW$, formBtnW$], { x: 20, opacity: 0, duration: 1.5, stagger: 0.1 }, 0.2)
    // .from(formBtnW$, { opacity: 0, duration: 1.5 }, 1)
  })
  return ctx
}
