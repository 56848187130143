import { EventInterface } from '@splidejs/splide'
import gsap from 'gsap'

export function MyTransition(Splide, Components) {
  const { bind } = EventInterface(Splide)
  const { Move } = Components
  const { list } = Components.Elements
  const length = Splide.length - 1

  let endCallback
  let tl
  list.style.display = 'grid'
  const slides = Components.Elements.slides
  slides.forEach((element) => {
    element.style.gridArea = '1 / 1'
    if (element !== slides[0]) {
      const el$a = element.querySelectorAll(':scope>div>div')

      gsap.set([...el$a[0].querySelectorAll('.word'), ...el$a[2].querySelectorAll('.word')], { autoAlpha: 0 })
      gsap.set(el$a[1].querySelectorAll('.line'), { yPercent: 110 })
    }
  })

  function mount() {
    bind(list, 'transitionend', (e) => {
      if (e.target === list && endCallback) {
        // Removes the transition property
        cancel()

        // Calls the `done` callback
        endCallback()
      }
    })
  }

  function start(index, done) {
    // getIndex not prevIndex !
    const oldIndex = Components.Controller.getIndex({ prev: true })

    const toRight = (index > oldIndex && !(oldIndex === 0 && index === length)) || (index === 0 && oldIndex === length)

    // Retrieve the slide objects:
    const oldSlideObj = Components.Slides.getAt(oldIndex)
    const newSlideObj = Components.Slides.getAt(index)

    // Guard if either is missing (e.g. out of range or only 1 slide):
    if (!oldSlideObj || !newSlideObj) {
      done()
      return
    }

    const oldSlide = oldSlideObj.slide // DOM element
    const newSlide = newSlideObj.slide // DOM element
    gsap.set(newSlide, { autoAlpha: 1 }, 0)

    const oldEl$a = oldSlide.querySelectorAll(':scope>div>div')
    const newEl$a = newSlide.querySelectorAll(':scope>div>div')
    const oldClient$ = oldEl$a[0]
    const oldQuote$ = oldEl$a[1]
    const oldPerson$ = oldEl$a[2]
    const newClient$ = newEl$a[0]
    const newQuote$ = newEl$a[1]
    const newPerson$ = newEl$a[2]

    const direction = toRight ? 1 : -1
    const shift = 20 * direction

    const tlSet = { defaults: { duration: 3, ease: 'power4.out', stagger: 0.004, overwrite: true } }
    const tl = gsap.timeline(tlSet)
    tl.fromTo(oldClient$.querySelectorAll('.word'), { autoAlpha: 1 }, { autoAlpha: 0, stagger: 0.075, duration: 1 }, 0)
    tl.fromTo(newClient$.querySelectorAll('.word'), { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.075, duration: 1 }, 0.2)
    tl.fromTo(oldQuote$.querySelectorAll('.line'), { yPercent: 0, autoAlpha: 1 }, { yPercent: -110 * direction, autoAlpha: 0, duration: 1.5 }, 0)
    tl.fromTo(newQuote$.querySelectorAll('.line'), { yPercent: 110 * direction, autoAlpha: 0 }, { yPercent: 0, duration: 1.5, autoAlpha: 1 }, 0.2)
    //
    const fadeDur = { duration: 0.5, stagger: 0 }
    const xDur = { duration: 2, stagger: 0 }
    tl.fromTo(oldPerson$.querySelectorAll('.word'), { autoAlpha: 1 }, { autoAlpha: 0, ...fadeDur, ease: 'none' }, 0.0)
    tl.fromTo(newPerson$.querySelectorAll('.word'), { autoAlpha: 0 }, { autoAlpha: 1, ...fadeDur, ease: 'none' }, 0.5)
    tl.fromTo(oldPerson$, { x: 0 }, { x: -shift, ...xDur, ease: 'power4.out' }, 0.0)
    tl.fromTo(newPerson$, { x: shift }, { x: 0, ...xDur, ease: 'power4.out' }, 0.25)

    // tl.fromTo(oldPerson$, { x: 0, autoAlpha: 1 }, { x: -shift, autoAlpha: 0 }, 0.0)
    // tl.fromTo(newPerson$, { x: shift, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, 0.1)

    endCallback = done
  }

  function cancel() {
    const oldIndex = Components.Controller.getIndex({ prev: true })
    const newIndex = Components.Controller.getIndex()
    const oldSlide = Components.Slides.getAt(oldIndex).slide
    const newSlide = Components.Slides.getAt(newIndex).slide
    // gsap.set(newSlide, { autoAlpha: 1 }, 0)
    // gsap.set(oldSlide, { autoAlpha: 0 }, 0)
  }

  return {
    mount,
    start,
    cancel,
  }
}
