import SplitType from 'split-type'
import { addStaticSplideClasses, debounce, hexToRgb, mm, sel, selAll, stLineWrap, trackDirection } from './utils'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

export const primeColor = getComputedStyle(document.documentElement).getPropertyValue('--color--prime')

const getVal = (val) => getComputedStyle(document.documentElement).getPropertyValue(val)

export const color = {
  floor: getVal('--color--floor'),
  ceiling: getVal('--color--ceiling'),
  gray1: getVal('--color--gray-1'),
  gray2: getVal('--color--gray-2'),
  gray3: getVal('--color--gray-3'),
  gray4: getVal('--color--gray-4'),
}

export const navbarOld = () => {
  const navbarW$ = sel('.navbar-w')
  const navbar$ = navbarW$.querySelector('.w-nav')
  if (!navbar$) return
  const navbarBtn$ = navbar$.querySelector('.button.is-secondary')
  const navbarMobBtn$a = navbar$.querySelectorAll('.w-nav-button>div>div')
  const navbarMobMenu$ = navbar$.querySelector('.w-nav-menu')

  const navbarHeight = navbar$.offsetHeight
  navbarW$.style.height = `${navbarHeight}px`
  navbar$.style.position = 'fixed'
}

export const navbar = (dataPage) => {
  const navbar$ = sel('.navbar')
  if (!navbar$) return

  const navbarW$ = sel('.navbar-w')

  const navbarHeight = navbar$.offsetHeight
  navbarW$.style.height = `${navbarHeight}px`
  navbar$.style.position = 'fixed'
  const navbarCtx = gsap.context(() => {
    mm.add('(max-width: 991px)', () => {
      const menuBtn$ = sel('.is--menu')
      const navbarMenu$ = sel('.navbar__menu')
      const navbarMenuAll$a = selAll('.navbar__menu>div')
      const navbarA$a = selAll('.navbar__link')
      const navbarBtnW$ = sel('.navbar__menu-buttons')
      const navbarBtn$a = navbarBtnW$.querySelectorAll('.button')
      const navbarFooter$ = sel('.navbar__footer')
      const navbarFooterA$a = selAll('.navbar__footer a')
      const navbarC$ = sel('.navbar1_container')
      const navbarLogoA$ = sel('.navbar__logo-w')

      const navbarAsT = new SplitType(navbarA$a, { types: 'lines, words' })
      stLineWrap(navbarAsT.lines)
      // const navbarFooterAsT = new SplitType(navbarFooterA$a, { types: 'lines, words' })
      // stLineWrap(navbarFooterAsT.lines)

      function easeRev(timeline, ease = 'power4.out', easeReversed = 'power4.out') {
        // We'll return an actual function that GSAP can call per frame
        return function (p) {
          if (timeline.reversed()) {
            const backwardEase = gsap.parseEase(easeReversed)
            // Typically p goes from 1 -> 0 in reverse, so we might do:
            const reversedP = 1 - p
            return 1 - backwardEase(reversedP)
          } else {
            const forwardEase = gsap.parseEase(ease)
            return forwardEase(p)
          }
        }
      }

      const tl = gsap.timeline({ paused: true, defaults: { duration: 1 } })
      tl.fromTo(
        navbarMenu$,
        { backgroundColor: hexToRgb(color.floor, 0) },
        { backgroundColor: hexToRgb(color.floor, 1), ease: easeRev(tl, 'power4.out', 'power4.in') },
        0
      )
        .from(
          navbarAsT.lines,
          { y: '1.3em', stagger: { amount: dataPage === 'about' ? 0.15 : 0 }, ease: easeRev(tl, 'power4.out', 'power3.in'), duration: 0.95 },
          0.5
        )
        .from(navbarBtnW$, { opacity: 0, duration: 0.5 }, 0.5)
        .from(navbarFooter$, { opacity: 0 }, 0)
        .from(navbarFooterA$a, { x: 20, opacity: 0, ease: 'power4.out', stagger: { amount: 0.3 }, duration: 0.7 }, 0)
        .to(
          {},
          {
            onStart() {
              // is--open has display block, so we need to add it before the animation starts
              navbarMenu$.classList.add('is--open')
            },
            onReverseComplete() {
              navbarMenu$.classList.remove('is--open')
            },
          },
          0
        )
        .to(
          {},
          {
            duration: 0.1,
            onStart() {
              menuBtn$.textContent = 'Close'
            },
            onReverseComplete() {
              menuBtn$.textContent = 'Menu'
            },
          },
          tl.duration() / 2
        )

      const tlSettings = {
        // duration: 1.2,
        // ease: 'power4.out',
        // overwrite: 'auto',
      }
      function tlOpen() {
        gsap.getById('navbarMobTlOut')?.pause()
        gsap.to(tl, { progress: 1, ...tlSettings, duration: 1.2, id: 'navbarMobTlIn' })
        const theme = navbar$.dataset.theme
        // console.log('theme', theme)
        if (theme !== 'dark') {
          gsap.getById('navbarMobColorOut')?.pause()
          gsap.to([navbar$], { '--color--theme-fg': color.ceiling, ease: 'none', id: 'navbarMobColorIn' })
        }
        tl.reversed(false)
      }
      function tlClose() {
        gsap.getById('navbarMobTlIn')?.pause()
        gsap.to(tl, { progress: 0, ...tlSettings, duration: 0.8, id: 'navbarMobTlOut' })
        const theme = navbar$.dataset.theme
        if (theme !== 'dark') {
          gsap.getById('navbarMobColorIn')?.pause()
          gsap.to([navbar$], { '--color--theme-fg': color.floor, ease: 'none', id: 'navbarMobColorOut' })
        }
        tl.reversed(true)
      }
      menuBtn$.addEventListener('click', (e) => {
        if (gsap.isTweening(tl)) {
          if (tl.reversed()) {
            tlOpen()
          } else {
            tlClose()
          }
        } else {
          if (navbarMenu$.classList.contains('is--open')) {
            tlClose()
          } else {
            tlOpen()
          }
        }
      })
      ;[...navbarA$a, navbarLogoA$, ...navbarBtn$a, ...navbarFooterA$a].forEach((el) => {
        el.addEventListener('click', () => {
          tlClose()
        })
      })
    })
  })
  return { navbarHeight, navbarCtx }
}

export const insights = (container, isTouchScreen, isTouch) => {
  const sel = (el) => container.querySelector(el)
  const selAll = (el) => container.querySelectorAll(el)
  if (!sel('.insight__item')) return
  // if (!sel('.insight__item')) return console.log('No insight items found')

  function easeRev(timeline) {
    // We'll return an actual function that GSAP can call per frame
    return function (p) {
      if (timeline.reversed()) {
        // console.log('reversed')
        const backwardEase = gsap.parseEase('power1.in')
        // Typically p goes from 1 -> 0 in reverse, so we might do:
        const reversedP = 1 - p
        return 1 - backwardEase(reversedP)
      } else {
        // console.log('forward')
        const forwardEase = gsap.parseEase('power4.out')
        return forwardEase(p)
      }
    }
  }
  function _insight(items = []) {
    return gsap.context(() => {
      let itemsArr
      // setTimeout(() => {
      // console.log(items)
      if (items.length > 0) {
        itemsArr = items
        // console.log(itemsArr)
      }
      // }, 500)
      const insightItem$a = itemsArr || selAll('.insight__item')

      if (!insightItem$a.length) return console.warn('No collection items found')
      if (!itemsArr) {
        gsap.from(insightItem$a, {
          x: 50,
          opacity: 0,
          // if _insight called by fs filter or load, stagger 0.3, else 2
          stagger: { amount: 2 },
          duration: 2,
          ease: 'power4.out',
          scrollTrigger: { trigger: insightItem$a[0], start: 'top 80%', end: '+=1', toggleActions: 'play none reverse none' },
        })
      }
      if (isTouch) return
      insightItem$a.forEach((el) => {
        const insightCat$ = el.querySelector('.insight__item__category:not(.is--secondary)')
        if (insightCat$ && !insightCat$.stInit) {
          insightCat$.stInit = true
          stLineWrap(insightCat$, 'insight__item__category-w')
          // const sec$ = insightCat$.querySelector('.is--secondary')

          let div = document.createElement('div')
          div.classList.add('insight__item__category', 'is--secondary')
          div.textContent = insightCat$.textContent
          insightCat$.after(div)
          // console.log(insightCat$)
        }

        const insightCatW$ = el.querySelector('.insight__item__category-w')
        if (insightCatW$) {
          const insightItem$ = el
          const insightA$ = insightItem$.querySelector('a')
          const catPrim$ = el.querySelector('.insight__item__category:not(.is--secondary)')
          const catSec$ = el.querySelector('.insight__item__category.is--secondary')

          // TODO turn into async (observer?)
          // no idea why but SplitType has to be offset with finsweet filter
          setTimeout(() => {
            const catPrimSt = catPrim$?.st || new SplitType(catPrim$, { types: 'lines, words, chars', lineClass: 'st-line' })
            if (!catPrim$.st) catPrim$.st = catPrimSt
            // console.log(catPrim$?.st)

            const catSecSt = catSec$?.st || new SplitType(catSec$, { types: 'lines, words, chars', lineClass: 'st-line' })
            if (!catSec$.st) {
              catSec$.st = catSecSt
              // delete catSec$.st
              gsap.set(catSecSt.chars, { y: '1.2em' })

              const charEase = 'power4.out'
              const insightCatTl = gsap.timeline({ defaults: { duration: 1, ease: 'none' }, paused: true })
              // console.log(catPrimSt.chars)

              insightCatTl
                .to(catPrimSt.chars, { y: '-1.2em', stagger: { amount: 0.5 }, ease: charEase, duration: 0.2 }, 0)
                .to(catSecSt.chars, { y: '0em', stagger: { amount: 0.5 }, ease: charEase, duration: 0.2 }, 0)
                .to(
                  insightA$,
                  {
                    x: !isTouchScreen && 20,
                    duration: 1,
                    ease: easeRev(insightCatTl),
                  },
                  0
                )

              // insightItem$.addEventListener('mouseenter', () => {
              insightItem$.onmouseenter = () => {
                insightCatTl.reversed(false)

                // insightCatTl.play()
                gsap.to(insightCatTl, { duration: 1, progress: 1, ease: 'power1.out' })
              }
              // insightItem$.addEventListener('mouseleave', () => {
              insightItem$.onmouseleave = () => {
                insightCatTl.reversed(true)

                // insightCatTl.reverse()
                gsap.to(insightCatTl, { duration: 1, progress: 0, ease: 'power4.out' })
              }
            }
          }, 1000)
        }
      })
    })
  }

  let ctx = _insight()

  // window.fsAttributes = window.fsAttributes || []
  //
  // window.fsAttributes.push([
  //   'cmsfilter',
  //   (filterInstances) => {
  //     // The callback passes a `filterInstances` array with all the `CMSFilters` instances on the page.
  //     const [filterInstance] = filterInstances
  //
  //     // The `renderitems` event runs whenever the list renders items after filtering.
  //     filterInstance?.listInstance.on('renderitems', (renderedItems) => {
  //       // console.log('renderedItems', renderedItems[0].element)
  //
  //       // ctx?.revert()
  //       setTimeout(() => {
  //         // ctx = _insight(renderedItems)
  //       }, 10)
  //     })
  //   },
  // ])

  // fsAttributes.push is not working for load more after barba transition
  const list = debounce(() => {
    const insightItems = selAll('.insight__item')
    // console.log('list', insightItems.length)
    // ctx.revert()
    ctx = _insight(insightItems)
  })
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList') {
        list()
      }
    })
  })
  const insightContainer = sel('.insight__list')
  if (insightContainer) observer.observe(insightContainer, { childList: true })

  return ctx
}

export const footer = () => {
  const ctx = gsap.context(() => {
    const ctaSec$ = sel('.cta-sec')
    if (!ctaSec$) return
    const ctaH$ = sel('.cta__h')
    const ctaA$a = selAll('.cta__nav a')
    const ctaHst = new SplitType(ctaH$, { types: 'lines, words' })
    const ctaPst = new SplitType('.subtitle.is--footer', { types: 'lines, words, chars' })

    stLineWrap(ctaHst.lines)
    stLineWrap(ctaPst.lines)
    stLineWrap([sel('.footer__mail')])
    const footerMail$ = sel('.footer__mail')

    const ctaTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 2 } })

    ctaTl
      .from(ctaHst.lines, { y: '1.2em', stagger: { amount: 0.1 }, duration: 1 }, 0)
      .from(ctaA$a, { x: 50, opacity: 0, stagger: 0.15 }, 0.2)
      .from(ctaPst.lines, { y: '1.2em', stagger: { amount: 0 }, duration: 1 }, 0.65)
      .from(footerMail$, { y: 100, duration: 1.25 }, '>-1')

    ScrollTrigger.create({
      trigger: ctaSec$,
      start: 'top 75%',
      end: '+=1',
      toggleActions: 'play none reverse none',
      animation: ctaTl,
    })

    const slider = sel('.footer__h-w')
    addStaticSplideClasses(slider)
    const splide = new Splide(slider, {
      gap: '1.5rem',
      pagination: false,
      arrows: false,
      autoWidth: true,
      drag: 'free',
      type: 'loop',
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
    }).mount({ AutoScroll })
  })
  return ctx
}
